import { combineReducers } from 'redux';
import auth from './auth/reducer';
import api from './api/reducer'
import theme from './theme/reducer'
import base from './base/reducer'
import leads from './leads/reducer'
import reports from './reports/reducer'

const reducers = combineReducers({
  api,
  auth,
  theme,
  base,
  leads,
  reports
});

export default reducers;