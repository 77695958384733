import { REHYDRATE } from 'redux-persist';
import {
  LEADS_FETCH,
  LEADS_FETCH_BY_ID,
  LEADS_FETCH_TIMELINE_BY_ID,
  LEADS_SET_FILTERS,
  LEADS_FETCH_NOTES_BY_ID,
  LEADS_FETCH_EMAIL_TEMPLATES,
  LEADS_FETCH_EMAIL_THREAD_BY_ID,
  LEADS_FETCH_EMAIL_THREAD_CLEAR,
  LEADS_FETCH_EMAIL_BY_ID,
  LEADS_CALL_HISTORY,  
} from '../actions';

const INIT_STATE = {
  list: [],
  list_paging: { total_rows: 0, page_size: 25, page_indx: 0 },
  lead: null,
  notes: [],
  mail: [],
  mail_thread: [],
  mail_templates: [],
  call_history: [],
  timeline: [],
  grid_options: {
    lead: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {
      }
    }
  },
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEADS_FETCH.SUCCESS: {
      const { leads, total_rows, page_size, page_indx } = action.payload
      return { ...state, list: leads, list_paging: { total_rows, page_size, page_indx } }
    }
    case LEADS_FETCH_BY_ID.SUCCESS: {
      return { ...state, lead: action.payload }
    }
    case LEADS_FETCH_TIMELINE_BY_ID.REQUEST: {
      return { ...state, timeline: [] }
    }
    case LEADS_FETCH_TIMELINE_BY_ID.SUCCESS: {
      return { ...state, timeline: action.payload }
    }
    case LEADS_SET_FILTERS.REQUEST: {
      return { ...state, grid_options: {
        ...state.grid_options,
        lead: {
          ...state.grid_options.lead,
          filters: {
            ...state.grid_options.lead.filters,
            ...action.payload,
          }
        },
      } }
    }
    case LEADS_FETCH_NOTES_BY_ID.REQUEST: {
      return { ...state, notes: [] }
    }
    case LEADS_FETCH_NOTES_BY_ID.SUCCESS: {
      return { ...state, notes: action.payload }
    }
    case LEADS_FETCH_EMAIL_TEMPLATES.REQUEST: {
      return { ...state, mail_templates: [] }
    }
    case LEADS_FETCH_EMAIL_TEMPLATES.SUCCESS: {
      return { ...state, mail_templates: action.payload }
    }
    case LEADS_FETCH_EMAIL_BY_ID.REQUEST: {
      return { ...state, mail: [] }
    }
    case LEADS_FETCH_EMAIL_BY_ID.SUCCESS: {
      return { ...state, mail: action.payload }
    }
    case LEADS_FETCH_EMAIL_THREAD_BY_ID.REQUEST:
    case LEADS_FETCH_EMAIL_THREAD_CLEAR.REQUEST: {
      return { ...state, mail_thread: [] }
    }
    case LEADS_FETCH_EMAIL_THREAD_BY_ID.SUCCESS: {
      return { ...state, mail_thread: action.payload }
    }
    case LEADS_CALL_HISTORY.REQUEST: {
      return { ...state, call_history: [] }
    }
    case LEADS_CALL_HISTORY.SUCCESS: {
      return { ...state, call_history: action.payload }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
