import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  LEADS_FETCH,
  LEADS_SET_FILTERS,
  LEADS_FETCH_BY_ID,
  LEADS_FETCH_TIMELINE_BY_ID,
  LEADS_FETCH_NOTES_BY_ID,
  LEADS_ADD_NOTES_BY_ID,
  LEADS_EMAIL_ACKNOWLEDGE_ALL,
  LEADS_EMAIL_ACKNOWLEDGE,
  LEADS_FETCH_EMAIL_TEMPLATE_CONTENT,
  LEADS_FETCH_EMAIL_TEMPLATES,
  LEADS_ADD_EMAILS_BY_ID,
  LEADS_FETCH_EMAIL_THREAD_BY_ID,
  LEADS_FETCH_EMAIL_BY_ID,
  REPORT_PRE_QUAL,
  LEADS_CALL_HISTORY,
  LEADS_SET_FORN_DATA,
} from '../actions';

export function* watchLeadFetchRequest() {
  const action = LEADS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.lead.filters||{}
      const data = {...filters, ...payload}
      const { mode, ...post } = data
      return {
        method: 'post',
        url: `${API_BASE}/leads/${mode||'all'}/fetch`,
        data: post
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}
export function* watchLPromptFiltersRequest() {
  yield takeEvery(LEADS_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH.requestAction({}))
  })
}

export function* watchLeadFetchIdRequest() {
  const action = LEADS_FETCH_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'get',
        url: `${API_BASE}/leads/fetch/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadTimelineFetchIdRequest() {
  const action = LEADS_FETCH_TIMELINE_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'get',
        url: `${API_BASE}/leads/timeline/${payload.id}`
      }
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsNotesRequest() {
  const action = LEADS_FETCH_NOTES_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/leads/notes/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsAddNotesRequest() {
  const action = LEADS_ADD_NOTES_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'post',
        url: `${API_BASE}/leads/notes/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailsRequest() {
  const action = LEADS_FETCH_EMAIL_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/comms/mail/fetch/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailThreadRequest() {
  const action = LEADS_FETCH_EMAIL_THREAD_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/comms/mail/fetch/thread/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsAddEmailsRequest() {
  const action = LEADS_ADD_EMAILS_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'post',
        url: `${API_BASE}/comms/mail/send`,
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailTemplatesRequest() {
  const action = LEADS_FETCH_EMAIL_TEMPLATES
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/comms/mail/templates`,
      }
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailTemplateContentRequest() {
  const action = LEADS_FETCH_EMAIL_TEMPLATE_CONTENT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/comms/mail/template/content/${payload.template_id}/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsEmailAcknowledgeRequest() {
  const action = LEADS_EMAIL_ACKNOWLEDGE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/comms/mail/acknowledge/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailAcknowledgeAllRequest() {
  const action = LEADS_EMAIL_ACKNOWLEDGE_ALL
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/comms/mail/acknowledge/all/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsCallHistory() {
  const action = LEADS_CALL_HISTORY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
    },
    requestOptionsFromPayload: (payload) => {
      const { id } = payload
      return {
        url: `${API_BASE}/comms/call/history/${id}`
      }
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsSetFormData() {
  const action = LEADS_SET_FORN_DATA
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/leads/form/data`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}


export default function* rootSaga() {
  yield all([
    fork(watchLeadFetchRequest),
    fork(watchLeadFetchIdRequest),
    fork(watchLeadsCallHistory),
    fork(watchLPromptFiltersRequest),
    fork(watchLeadTimelineFetchIdRequest),
    fork(watchLeadsNotesRequest),
    fork(watchLeadsAddNotesRequest),
    fork(watchLeadsEmailsRequest),
    fork(watchLeadsAddEmailsRequest),
    fork(watchLeadsEmailThreadRequest),
    fork(watchLeadsEmailTemplatesRequest),
    fork(watchLeadsEmailTemplateContentRequest),
    fork(watchLeadsEmailAcknowledgeRequest),
    fork(watchLeadsEmailAcknowledgeAllRequest),
    fork(watchLeadsSetFormData),
  ]);
}