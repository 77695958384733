import axios from 'axios';
import qs from 'qs';
import { SERVICE_PATH } from '../../constants/default.constant';
import { checkResponseData } from '../../utils' 

export const apiAction = (action, payload) => ({
  type: action.REQUEST,
  payload: payload
});

export const apiActionSuccess = (action, payload) => ({
  type: action.SUCCESS,
  payload: payload
});

export const apiActionError = (action, payload) => ({
  type: action.ERROR,
  payload: payload
});

export const apiRequestAction = async (method, path, body, options) => {
  body = body ? body : {}
  options = options ? options : {}
  const defaults = {
    use_form_data: false,
    auth_strategy: 'jwt',
    jwt_token: null,
    progress: null
  }
  options = {...defaults, ...options}

  let headers = {}
  if (options.use_form_data === true) {
    if (body instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    } else {
      body = qs.stringify(body)
    }
  }
  headers['x-auth-strategy'] = options.auth_strategy
  switch (options.auth_strategy) {
    case 'jwt':
      const { jwt_token } = options;
      headers['Authorization'] =`Bearer ${jwt_token}`
      break;
    default:
      break;
  }
  // console.log(method, path, jwt_token, body)
  method = method ? method : 'post';
  let promise;
  let url;
  if (path.indexOf('http') === 0) {
    url = path;
  } else {
    url = `${SERVICE_PATH}${path}`
  }
  switch (method) {
    case 'get':
    case 'delete':
      promise = axios[method](url, { headers })
      break;
    case 'post':
    case 'patch':
      promise = axios[method](url, body, { 
        headers,
        onUploadProgress: (progressEvent) => {
          if (options.progress) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            options.progress(percentCompleted)
          }
        }
      })
      break;
    default:
      break;
  }
  return await promise.then(checkResponseData).catch(checkResponseData);
}