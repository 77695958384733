import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { apiSignUp } from 'services/AuthService'
import { LOGOUT_USER, loginUserSuccess } from 'redux/actions'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { API_BASE } from '../../constants/api.constant'
import api from 'utils/auth'
import { useEffect, useRef, useState } from 'react'

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const roles = useSelector((state) => state.auth.roles, shallowEqual)
	const [ authenticated, setAuthenticated ] = useState(roles && roles.length > 0)
	const prevAuthState = useRef()

	const userPassLogin = async ({ userName, password }) => {
		const { data: { result } } = await api.post(`${API_BASE}/auth/signin`, {
			email: userName,
			password: password
		})
		return result
	}

	const authStateChanged = () => {
		console.log('useAuth -> authStateChanged')
	}

	useEffect(() => {
		const authNew = roles && roles.length > 0
		if (authNew !== authenticated) {
			setAuthenticated(authNew)
		}
	}, [roles, authenticated])

	const signIn = async (values) => {
		try {
			const resp = await userPassLogin(values)
			if (resp) {
				const { email: username, first_name, last_name, uid: _id, roles, token } = resp

				let initials = ''
				if (first_name && first_name.length > 0) {
					initials += first_name[0]
				}
				if (last_name && last_name.length > 0) {
					initials += last_name[0]
				}
				let name = `${first_name} ${last_name}`.trim()
				if (initials.length === 0 && username && username.length > 0) {
					initials += username[0]
					name = username
				}
				initials = initials.toUpperCase()
				dispatch(loginUserSuccess({
					user: {
						_id,
						username,
						initials,
						name,
						first_name,
						last_name,
						profile_picture: '',
						roles,
						token
					}
				}))

				const entryPath = roles.includes('csi2admin') || roles.includes('csi2stand') ? appConfig.authenticatedEntryPath : appConfig.authenticatedEntryPathStandard
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				const url = redirectUrl ? redirectUrl : entryPath

				navigate(url)
				// window.location = url
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				// const { token } = resp.data
				// dispatch(onSignInSuccess(token))
				if (resp.data.user) {
					// dispatch(loginUserSuccess({
					// 	user: {
					// 		_id: 1,
					// 		username: '',
					// 		name: 'Anton',
					// 		profile_picture: '',
					// 		roles: ['admin']
					// 	}
					// }))
					// dispatch(firebaseAuth({
					// 	token: 'hello'
					// }))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const handleSignOut = async () => {
		dispatch(LOGOUT_USER.requestAction({}))
		if (window.location.pathname !== '/reset-password') {
			navigate(appConfig.unAuthenticatedEntryPath)
		}
	}

	const signOut = async () => {
		handleSignOut()
	}

	return {
		authenticated,
		authStateChanged,
		signIn,
		signUp,
		signOut
	}
}

export default useAuth