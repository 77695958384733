export const createAction = (action) => {
  const conf = {
    ACTION: action,
    REQUEST: `${action}_REQUEST`,
    SUCCESS: `${action}_SUCCESS`,
    ERROR: `${action}_ERROR`
  };
  conf.requestAction = (payload) => ({
    type: conf.REQUEST,
    payload: payload ? payload : {}
  })
  conf.successAction = (payload) => ({
    type: conf.SUCCESS,
    payload: payload ? payload : {}
  })
  conf.errorAction = (error) => ({
    type: conf.ERROR,
    payload: { error }
  })
  return conf
};

/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* THEME */
export const THEME_GET = "THEME_GET";
export const THEME_SET = "THEME_SET";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const LOGIN_FIREBASE = "LOGIN_FIREBASE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = createAction('LOGOUT_USER');
export const AUTH_RESET = createAction('AUTH_RESET')
export const ROLE_REQUEST = createAction('ROLE_REQUEST')

export const VERSION_CHECK = createAction('VERSION_CHECK')

export const FB_TOKEN_CHANGED = createAction('FB_TOKEN_CHANGED')

export const INITIAL_ROUTE = "INITIAL_ROUTE";

export const PUSH_REGISTER = "PUSH_REGISTER";
export const PUSH_REGISTER_STORE = "PUSH_REGISTER_STORE";
export const PUSH_REGISTER_SUCCESS = "PUSH_REGISTER_SUCCESS";
export const PUSH_REGISTER_ERROR = "PUSH_REGISTER_ERROR";

export const GENERIC_API_ERROR = "GENERIC_API_ERROR";

/* USERS */
export const USERS_FETCH = createAction('USERS_FETCH');
export const USERS_FETCH_BY_ID = createAction('USERS_FETCH_BY_ID');
export const USERS_FETCH_CLEAR = createAction('USERS_FETCH_CLEAR');
export const USERS_SET_SORT = createAction('USERS_SET_SORT');
export const USERS_SET_VIEW = createAction('USERS_SET_VIEW');
export const USERS_SET_SEARCH = createAction('USERS_SET_SEARCH');
export const USERS_SET_FILTERS = createAction('USERS_SET_FILTERS');
export const USERS_CRUD_MODE = createAction('USERS_CRUD_MODE');
export const USERS_CRUD_SUBMIT = createAction('USERS_CRUD_SUBMIT');
export const USERS_CREATE = createAction('USERS_CREATE');
export const USERS_EDIT = createAction('USERS_EDIT');
export const USERS_ACTIVE_TOGGLE = createAction('USERS_ACTIVE_TOGGLE');
export const USERS_ROLES_SET = createAction('USERS_ROLES_SET');
export const USERS_RESET_PASS_LINK = createAction('USERS_RESET_PASS_LINK');
export const USERS_RESET_PASS_SEND = createAction('USERS_RESET_PASS_SEND');
export const USERS_ACTIVITY = createAction('USERS_ACTIVITY');

/* PROMPTS */
export const LEADS_FETCH = createAction('LEADS_FETCH');
export const LEADS_FETCH_BY_ID = createAction('LEADS_FETCH_BY_ID');
export const LEADS_FETCH_TIMELINE_BY_ID = createAction('LEADS_FETCH_TIMELINE_BY_ID');
export const LEADS_SET_FILTERS = createAction('LEADS_SET_FILTERS');
export const LEADS_FETCH_AUDITS_BY_ID = createAction('LEADS_FETCH_AUDITS_BY_ID');
export const LEADS_FETCH_NOTES_BY_ID = createAction('LEADS_FETCH_NOTES_BY_ID');
export const LEADS_ADD_NOTES_BY_ID = createAction('LEADS_ADD_NOTES_BY_ID');
export const LEADS_FETCH_EMAIL_BY_ID = createAction('LEADS_FETCH_EMAIL_BY_ID');
export const LEADS_FETCH_EMAIL_THREAD_BY_ID = createAction('LEADS_FETCH_EMAIL_THREAD_BY_ID');
export const LEADS_ADD_EMAILS_BY_ID = createAction('LEADS_ADD_EMAILS_BY_ID');
export const LEADS_EMAIL_ACKNOWLEDGE = createAction('LEADS_EMAIL_ACKNOWLEDGE');
export const LEADS_EMAIL_ACKNOWLEDGE_ALL = createAction('LEADS_EMAIL_ACKNOWLEDGE_ALL');
export const LEADS_FETCH_EMAIL_THREAD_CLEAR = createAction('LEADS_FETCH_EMAIL_THREAD_CLEAR');
export const LEADS_FETCH_EMAIL_TEMPLATES = createAction('LEADS_FETCH_EMAIL_TEMPLATES');
export const LEADS_FETCH_EMAIL_TEMPLATE_CONTENT = createAction('LEADS_FETCH_EMAIL_TEMPLATE_CONTENT');
export const LEADS_SET_FORN_DATA = createAction('LEADS_SET_FORN_DATA');
export const LEADS_SET_MANUAL_STAGE = createAction('LEADS_SET_MANUAL_STAGE');
export const LEADS_SUBMIT_STATE = createAction('LEADS_SUBMIT_STATE');
export const LEADS_SUBMIT_MANAGER_NOTES = createAction('LEADS_SUBMIT_MANAGER_NOTES');
export const LEADS_CALL_HISTORY = createAction('LEADS_CALL_HISTORY');
export const REPORT_PRE_QUAL = createAction('REPORT_PRE_QUAL');
/* PROMPT */

export const REDUX_LOGGER = createAction('REDUX_LOGGER')

/* BASE */
export const SET_CURRENT_ROUTE_KEY = createAction('SET_CURRENT_ROUTE_KEY');

export * from "./api/actions";
export * from "./auth/actions";